import {getTechnicalModel} from "../common/CommonModel";

const noteModel = {
    ...getTechnicalModel(),
    title: '',
    description: '',
    userId: '',
    date: '',
    completed: false
};

export function getNoteModel() {
    return noteModel;
}
