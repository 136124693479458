import React from 'react';
import {Button} from 'reactstrap';
import PropTypes from "prop-types";
import './Pagination.css';

const PaginationButtons = ({pageInfo, handlePageChange}) => {
    if (pageInfo.totalPages < 2) return null;

    return (
        <div className="pagination-buttons">
            <Button
                color="primary"
                disabled={pageInfo.pageNumber === 0}
                onClick={() => handlePageChange(pageInfo.pageNumber - 1)}
            >
                Anterior
            </Button>
            <Button
                color="primary"
                disabled={pageInfo.pageNumber === pageInfo.totalPages - 1}
                onClick={() => handlePageChange(pageInfo.pageNumber + 1)}
            >
                Siguiente
            </Button>
        </div>
    );
};

PaginationButtons.propTypes = {
    pageInfo: PropTypes.shape({
        totalPages: PropTypes.number.isRequired,
        pageNumber: PropTypes.number.isRequired,
    }).isRequired,
    handlePageChange: PropTypes.func.isRequired,
};

export default PaginationButtons;
