import React from 'react';
import {useAuth} from "../auth/AuthContext";
import NoteList from "./NoteList";
import PropTypes from "prop-types";

const NotesWrapper = ({minimized, toggleMinimize}) => {
    const {authenticated} = useAuth();

    if (!authenticated) {
        if (!minimized) {
            toggleMinimize();
        }
        return null;
    }

    return (
        <NoteList minimized={minimized} toggleMinimize={toggleMinimize}/>
    );
};

NotesWrapper.propTypes = {
    minimized: PropTypes.bool,
    toggleMinimize: PropTypes.func
};

export default NotesWrapper;
