import {getTechnicalModel} from "../../common/CommonModel";

const FlightsModel = {
    flights: {
        ...getTechnicalModel(),
        flights: [],
        totalPrice: '',
    },
};

const AccommodationsModel = {
    accommodations: {
        ...getTechnicalModel(),
        accommodations: [],
        totalNights: '',
        totalPrice: '',
    },
};

const TransfersModel = {
    transfers: {
        ...getTechnicalModel(),
        transfers: [],
        totalPrice: '',
    },
};

const CarRentsModel = {
    carRents: {
        ...getTechnicalModel(),
        carRents: [],
        totalPrice: '',
    },
};

const PeopleModel = {
    people: {
        ...getTechnicalModel(),
        kids: [],
        numberOfAdults: '',
        numberOfBabies: '',
    },
};

const ActivitiesModel = {
    activities: {
        ...getTechnicalModel(),
        activities: [],
        totalPvp: '',
        totalCost: '',
        totalBenefit: '',
    },
};

const FinancingsModel = {
    financing: {
        ...getTechnicalModel(),
        totalPrice: '',
        financingPayments: [],
        comments: '',
        schedule: false,
        signed: false,
    },
};

const ExtrasModel = {
    extra: {
        ...getTechnicalModel(),
        forestPrice: '',
        insurancePrice: '',
        giftPrice: '',
        cancellationInsurance: '',
        extras: [],
    },
};

export function getFlightsModel() {
    return FlightsModel;
}

export function getAccommodationsModel() {
    return AccommodationsModel;
}

export function getTransfersModel() {
    return TransfersModel;
}

export function getCarRentsModel() {
    return CarRentsModel;
}

export function getPeopleModel() {
    return PeopleModel;
}

export function getActivitiesModel() {
    return ActivitiesModel;
}

export function getFinancingsModel() {
    return FinancingsModel;
}

export function getExtrasModel() {
    return ExtrasModel;
}

const BudgetDetailType = {
    FLIGHT: 'Vuelo',
    ACCOMMODATION: 'Alojamiento',
    TRANSFER: 'Traslado',
    CAR_RENT: 'Alquiler de transporte',
    ACTIVITY: 'Actividad',
    PAYMENT: 'Pago',
    FINANCING: 'Financiación',
    TASK: 'Tarea',
    INCIDENT: 'Observación/Incidencia',
    EXTRA: 'Extras',
};

export function getBudgetDetailType() {
    return BudgetDetailType;
}

const BudgetDetailTypeParentLists = {
    FLIGHT: 'flights',
    ACCOMMODATION: 'accommodations',
    TRANSFER: 'transfers',
    CAR_RENT: 'carRents',
    ACTIVITY: 'activities',
    FINANCING: 'financing',
};

export function getBudgetDetailTypeParentLists() {
    return BudgetDetailTypeParentLists;
}

const BudgetDetailTypeLists = {
    FLIGHT: 'flights',
    ACCOMMODATION: 'accommodations',
    TRANSFER: 'transfers',
    CAR_RENT: 'carRents',
    ACTIVITY: 'activities',
    PAYMENT: 'payments',
    FINANCING: 'financingPayments',
    TASK: 'tasks',
    INCIDENT: 'incidents',
    EXTRA: 'extras',
};

export function getBudgetDetailTypeLists() {
    return BudgetDetailTypeLists;
}

const BudgetDetailEndpoint = {
    FLIGHT: 'flight',
    ACCOMMODATION: 'accommodation',
    TRANSFER: 'transfer',
    CAR_RENT: 'car_rent',
    ACTIVITY: 'activity',
    PAYMENT: 'payment',
    FINANCING: 'financing',
    TASK: 'task',
    INCIDENT: 'incident',
    EXTRA: 'extra',
};

export function getBudgetDetailEndpoints() {
    return BudgetDetailEndpoint;
}

const ItemStatusKey = {
    CREATED: 'CREATED',
    BOOKED: 'BOOKED',
    PAYED: 'PAYED',
    CANCELLED: 'CANCELLED',
}

const ItemStatus = {
    CREATED: 'Creado',
    BOOKED: 'Reservado',
    PAYED: 'Pagado',
    CANCELLED: 'Cancelado',
}

const ItemStatusAbbreviation = {
    CREATED: '',
    BOOKED: 'R',
    PAYED: 'P',
    CANCELLED: 'C',
}

export function getItemStatusAbbreviation() {
    return ItemStatusAbbreviation;
}

const PaymentTypeKey = {
    PAYMENT: 'PAYMENT',
    REFUND: 'REFUND',
}

export function getPaymentTypeKey() {
    return PaymentTypeKey;
}

const PaymentType = {
    PAYMENT: 'Pago',
    REFUND: 'Reembolso',
}

const TaskCategory = {
    ITINERARY: 'ITINERARIO',
    AGREEMENT: 'CONTRATO',
    WHATSAPP_GROUP: 'GRUPO WHATSAPP',
    FAQ: 'FAQ',
    CAR_DOCUMENT: 'DOCUMENTO COCHE',
    RECEIPT: 'RECIBO',
    AGREEMENT_SIGNATURE: 'FIRMA CONTRATO',
    SCHEDULE: 'AGENDAR',
    GUIDEBOOK: 'MINIGUÍA',
    CALENDAR: 'CALENDARIO',
}

export function getTaskCategories() {
    return TaskCategory;
}

const PaymentCategoryKey = {
    FLIGHTS: 'FLIGHTS',
    ACCOMMODATIONS: 'ACCOMMODATIONS',
    TRANSFERS: 'TRANSFERS',
    CAR_RENTS: 'CAR_RENTS',
    DEPOSIT: 'DEPOSIT',
    EXCURSIONS: 'EXCURSIONS',
    FOREST: 'FOREST',
    INSURANCE: 'INSURANCE',
    VISA: 'VISA',
    TRAVELCAT: 'TRAVELCAT',
    OTHER: 'OTHER',
}

const PaymentCategory = {
    FLIGHTS: 'Vuelos',
    ACCOMMODATIONS: 'Alojamientos',
    TRANSFERS: 'Traslados',
    CAR_RENTS: 'Alquileres de coche',
    DEPOSIT: 'Depósito',
    EXCURSIONS: 'Excursiones',
    FOREST: 'Bosque',
    INSURANCE: 'Seguro',
    VISA: 'VISA',
    TRAVELCAT: 'Travelcat',
    OTHER: 'Otros',
}

export function getPaymentCategories() {
    return PaymentCategory;
}

const doublePattern = '^\\d+(\\.\\d+)?$';
const doubleErrorMessage = 'Introduce un número válido. Usa un punto para separar los decimales.';

const FlightModel = {
    ...getTechnicalModel(),
    itemStatus: ItemStatusKey.CREATED,
    splits: [
        {
            ...getTechnicalModel(),
            date: '',
            itinerary: '',
            company: '',
            supplier: '',
            landNextDay: '',
        },
    ],
    reservationCode: '',
    reservationUntil: '',
    price: '',
    excludeFromPrice: '',
    notes: '',
};

const FlightDictionary = {
    itemStatus: {
        name: 'Estado',
        type: 'select',
        values: ItemStatus,
    },
    splits: {
        name: 'Añade vuelos',
        type: 'split',
    },
    reservationCode: {
        name: 'Código Reserva',
        type: 'text',
        optional: true,
    },
    reservationUntil: {
        name: 'Reservado hasta',
        type: 'datetime-local',
    },
    price: {
        name: 'Precio',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    excludeFromPrice: {
        name: '¿Es una alternativa?',
        type: 'checkbox',
    },
    notes: {
        name: 'Notas',
        type: 'text',
    },
};

const AccommodationModel = {
    ...getTechnicalModel(),
    itemStatus: ItemStatusKey.CREATED,
    dateTime: '',
    deliveryDateTime: '',
    nights: '',
    destination: '',
    name: '',
    regime: '',
    room: '',
    stars: '',
    link: '',
    price: '',
    excludeFromPrice: '',
    supplier: '',
    notes: '',
};

const AccommodationDictionary = {
    itemStatus: {
        name: 'Estado',
        type: 'select',
        values: ItemStatus,
    },
    dateTime: {
        name: 'Fecha de entrada',
        type: 'date',
    },
    deliveryDateTime: {
        name: 'Fecha de salida',
        type: 'date',
    },
    nights: {
        name: 'Nº Noches',
        type: 'number',
    },
    destination: {
        name: 'Ubicación',
        type: 'text',
    },
    name: {
        name: 'Alojamiento',
        type: 'text',
    },
    regime: {
        name: 'Régimen',
        type: 'text',
    },
    room: {
        name: 'Habitación',
        type: 'text',
    },
    stars: {
        name: 'Estrellas',
        type: 'number',
        optional: true,
    },
    link: {
        name: 'Enlace',
        type: 'text',
        optional: true,
    },
    price: {
        name: 'Precio',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    excludeFromPrice: {
        name: '¿Es una alternativa?',
        type: 'checkbox',
    },
    supplier: {
        name: 'Proveedor',
        type: 'text',
    },
    notes: {
        name: 'Notas',
        type: 'text',
    },
};

const TransferModel = {
    ...getTechnicalModel(),
    itemStatus: ItemStatusKey.CREATED,
    dateTime: '',
    origin: '',
    destination: '',
    price: '',
    excludeFromPrice: '',
    supplier: '',
    notes: '',
};

const TransferDictionary = {
    itemStatus: {
        name: 'Estado',
        type: 'select',
        values: ItemStatus,
    },
    dateTime: {
        name: 'Fecha',
        type: 'datetime-local',
    },
    origin: {
        name: 'Ubicación recogida',
        type: 'text',
    },
    destination: {
        name: 'Ubicación destino',
        type: 'text',
    },
    price: {
        name: 'Precio',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    excludeFromPrice: {
        name: '¿Es una alternativa?',
        type: 'checkbox',
    },
    supplier: {
        name: 'Proveedor',
        type: 'text',
    },
    notes: {
        name: 'Notas',
        type: 'text',
    },
};

const CarRentModel = {
    ...getTechnicalModel(),
    itemStatus: ItemStatusKey.CREATED,
    dateTime: '',
    deliveryDateTime: '',
    origin: '',
    destination: '',
    carModel: '',
    price: '',
    excludeFromPrice: '',
    supplier: '',
    notes: '',
};

const CarRentDictionary = {
    itemStatus: {
        name: 'Estado',
        type: 'select',
        values: ItemStatus,
    },
    dateTime: {
        name: 'Fecha de recogida',
        type: 'datetime-local',
    },
    deliveryDateTime: {
        name: 'Fecha de devolución',
        type: 'datetime-local',
    },
    origin: {
        name: 'Recogida',
        type: 'text',
    },
    destination: {
        name: 'Devolución',
        type: 'text',
    },
    carModel: {
        name: 'Modelo',
        type: 'text',
    },
    price: {
        name: 'Precio',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    excludeFromPrice: {
        name: '¿Es una alternativa?',
        type: 'checkbox',
    },
    supplier: {
        name: 'Proveedor',
        type: 'text',
    },
    notes: {
        name: 'Notas',
        type: 'text',
    },
};

const KidModel = {
    ...getTechnicalModel(),
    age: '',
};

const KidDictionary = {
    age: {
        name: 'Edad',
        type: 'number',
    },
};

const PaymentModel = {
    ...getTechnicalModel(),
    position: '',
    total: '',
    date: '',
    paymentType: PaymentTypeKey.PAYMENT,
    splits: [
        {
            ...getTechnicalModel(),
            paymentCategory: PaymentCategoryKey.FLIGHTS,
            amount: '',
        },
    ],
};

const PaymentDictionary = {
    position: {
        name: 'Número de pago',
        type: 'number',
    },
    total: {
        name: 'Total',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    date: {
        name: 'Fecha de realización',
        type: 'date',
    },
    paymentType: {
        name: 'Tipo de pago',
        type: 'select',
        values: PaymentType,
    },
    splits: {
        name: 'Categoría',
        type: 'split',
    },
};

const ActivityModel = {
    ...getTechnicalModel(),
    itemStatus: ItemStatusKey.CREATED,
    dateTime: '',
    name: '',
    supplier: '',
    price: '',
    excludeFromPrice: '',
    pvp: '',
    benefit: '',
    notes: '',
};

const ActivityDictionary = {
    itemStatus: {
        name: 'Estado',
        type: 'select',
        values: ItemStatus,
    },
    dateTime: {
        name: 'Día y hora',
        type: 'datetime-local',
    },
    name: {
        name: 'Excursión o actividad',
        type: 'text',
    },
    supplier: {
        name: 'Proveedor',
        type: 'text',
    },
    price: {
        name: 'N',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    excludeFromPrice: {
        name: '¿Es una alternativa?',
        type: 'checkbox',
    },
    pvp: {
        name: 'PVP',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    benefit: {
        name: 'Beneficio',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    notes: {
        name: 'Notas',
        type: 'text',
    },
};

const FinancingModel = {
    ...getTechnicalModel(),
    position: '',
    date: '',
    total: '',
    status: '',
};

const FinancingDictionary = {
    position: {
        name: 'Posición',
        type: 'number',
    },
    date: {
        name: 'Fecha',
        type: 'date',
    },
    total: {
        name: 'Precio',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    status: {
        name: 'Pagado',
        type: 'checkbox',
    },
};

const TaskModel = {
    ...getTechnicalModel(),
    taskCategory: '',
    maxDate: '',
    status: '',
    notes: '',
};

const TaskDictionary = {
    taskCategory: {
        name: 'Categoría',
        type: 'select',
        values: TaskCategory,
        disable: true,
    },
    maxDate: {
        name: 'Fecha máxima',
        type: 'date',
    },
    status: {
        name: 'Estado',
        type: 'checkbox',
    },
    notes: {
        name: 'Notas',
        type: 'text',
    },
};

const IncidentModel = {
    ...getTechnicalModel(),
    date: '',
    comment: '',
};

const IncidentDictionary = {
    date: {
        name: 'Fecha',
        type: 'date',
    },
    comment: {
        name: 'Observación / Incidencia',
        type: 'text',
    },
};

const ExtraModel = {
    ...getTechnicalModel(),
    extraType: '',
    price: '',
    notes: '',
};

const ExtraDictionary = {
    extraType: {
        name: 'Tipo',
        type: 'text',
    },
    price: {
        name: 'Precio',
        type: 'text',
        pattern: doublePattern,
        error: doubleErrorMessage,
    },
    notes: {
        name: 'Notas',
        type: 'text',
    },
};

export function getFlightModel() {
    return FlightModel;
}

export function getAccommodationModel() {
    return AccommodationModel;
}

export function getTransferModel() {
    return TransferModel;
}

export function getCarRentModel() {
    return CarRentModel;
}

export function getKidModel() {
    return KidModel;
}

export function getPaymentModel() {
    return PaymentModel;
}

export function getActivityModel() {
    return ActivityModel;
}

export function getFinancingModel() {
    return FinancingModel;
}

export function getTaskModel() {
    return TaskModel;
}

export function getIncidentModel() {
    return IncidentModel;
}

export function getExtraModel() {
    return ExtraModel;
}

export function getFlightDictionary() {
    return FlightDictionary;
}

export function getAccommodationDictionary() {
    return AccommodationDictionary;
}

export function getTransferDictionary() {
    return TransferDictionary;
}

export function getCarRentDictionary() {
    return CarRentDictionary;
}

export function getKidDictionary() {
    return KidDictionary;
}

export function getPaymentDictionary() {
    return PaymentDictionary;
}

export function getActivityDictionary() {
    return ActivityDictionary;
}

export function getFinancingDictionary() {
    return FinancingDictionary;
}

export function getTaskDictionary() {
    return TaskDictionary;
}

export function getIncidentDictionary() {
    return IncidentDictionary;
}

export function getExtraDictionary() {
    return ExtraDictionary;
}
