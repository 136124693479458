import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {getNoteModel} from "./NoteModel";
import moment from "moment/moment";

const NoteModal = ({isOpen, toggle, handleSave, initialNote}) => {
    const [newNote, setNewNote] = useState(getNoteModel());

    useEffect(() => {
        if (initialNote) {
            setNewNote(initialNote);
        } else {
            setNewNote(getNoteModel());
        }
    }, [initialNote]);

    const handleInputChange = (e) => {
        let {name, value} = e.target;
        setNewNote(prevNote => ({
            ...prevNote,
            [name]: value
        }));
    };

    const handleInputDateChange = (e) => {
        let {name, value} = e.target;
        setNewNote(prevNote => ({
            ...prevNote,
            [name]: moment(value).format("YYYY-MM-DD")
        }));
    };

    const onSave = () => {
        handleSave(newNote);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Añade nueva tarea</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label htmlFor="title">Título *</Label>
                    <Input
                        type="text"
                        id="title"
                        name="title"
                        required
                        value={newNote.title}
                        onChange={handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="description">Descripción</label>
                    <Input
                        type="textarea"
                        id="description"
                        name="description"
                        value={newNote.description}
                        onChange={handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="userId">Usuario (tu usuari si lo dejas en blanco)</Label>
                    <Input
                        type="text"
                        id="userId"
                        name="userId"
                        value={newNote.userId}
                        onChange={handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="date">Fecha *</Label>
                    <Input
                        className="input-date"
                        type="date"
                        id="date"
                        name="date"
                        required
                        value={newNote.date}
                        onChange={handleInputDateChange}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <FormGroup>
                    <Button color="success" onClick={onSave}
                            disabled={!newNote.title || !newNote.date}>Guardar</Button>{' '}
                    <Button color="danger" onClick={toggle}>Cancelar</Button>
                </FormGroup>
            </ModalFooter>
        </Modal>
    );
};

NoteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    initialNote: PropTypes.object
};

export default NoteModal;
