import {getTechnicalModel} from "../common/CommonModel";

const expedientModel = {
    ...getTechnicalModel(),
    travelcatId: '',
    assignedUserId: '',
    caseOpening: {
        ...getTechnicalModel(),
        email: '',
        phone: '',
        fromCity: '',
        availability: '',
        tripOfInterest: '',
        entrypoint: '',
        comments: '',
        customers: '',
    },
    caseTracking: {
        ...getTechnicalModel(),
        callAndSend: '',
        expectedDelivery: '',
        sentToClient: '',
        email: false,
        whatsApp: false,
        tracking: '',
        salesAppointment: '',
        askAmendments: false,
        expectedDeliveryAfterAmendments: '',
        updateSentToClient: '',
        emailAmendment: false,
        whatsAppAmendment: false,
        bookingEmail: false,
        openPostSalesFile: false,
    },
    postSales: {
        ...getTechnicalModel(),
        mailReservation: false,
        payment: false,
        idPass: false,
        whatsAppReservationConfirmed: false,
        itineraryReceiptAgreementEtc: false,
        schedule: false,
        agreementSignature: false,
        planningAppointment: false,
        laterChecklist: false,
        documentationDelivery: false,
        personalDocumentationReview: false,
        countryRestrictionReview: false,
        sentWhatsAppRestrictionsLink: false,
        planning: false,
        currencyExchange: false,
        networkData: false,
        costCalculation: false,
        emergencyPhone: false,
        restaurantsGuidebook: false,
        sendDigitalDocumentation: false,
        sendFinalPlanningAndActivitiesWithPrices: false,
        settleTripPayments: false,
        settleActivitiesVisaEtcPayments: false,
        bonusesDelivery: false,
        payEverything: false,
        refundPaymentServicesAtDestination: false,
    },
    customers: [],
};

export function getExpedientModel() {
    return expedientModel;
}
