import {getTechnicalModel} from "../common/CommonModel";

const scheduleModel = {
    ...getTechnicalModel(),
    userId: '',
    title: '',
    description: '',
    start: '',
    end: '',
    allDay: true,
    completed: false,
    color: '',
};

export function getScheduleModel() {
    return scheduleModel;
}
