import {getTechnicalModel} from "../common/CommonModel";

const customerModel = {
    ...getTechnicalModel(),
    firstName: '',
    firstSurname: '',
    secondSurname: '',
    phone: '',
    email: '',
    age: '',
    idNumber: '',
    passport: '',
    birthDate: '',
    address: '',
    comments: '',
    expedients: [],
};

export function getCustomerModel() {
    return customerModel;
}
