import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Button, ButtonGroup, Input, Label} from "reactstrap";
import {fetchData, handleResponse} from "../http/HttpUtil";
import Spinner from "../common/Spinner";
import './NoteList.css';
import {faChevronDown, faChevronUp, faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NoteModal from "./NoteModal";

const NoteList = ({minimized, toggleMinimize}) => {
    const [notes, setNotes] = useState([]);
    const [byUser, setByUser] = useState(true);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentNote, setCurrentNote] = useState(null);

    const fetchNotes = useCallback(() => {
        setLoading(true);
        const fetchUrl = byUser ?
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/note?byUser=true` :
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/note`;
        fetchData(fetchUrl, {})
            .then(response => handleResponse(response))
            .then(data => {
                setNotes(data.content);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
                setLoading(false);
            });
    }, [byUser]);

    useEffect(() => {
        fetchNotes();
    }, [fetchNotes, byUser]);

    const sortedNotes = [...notes].sort((a, b) => {
        if (a.date === b.date) {
            return a.id - b.id;
        }
        return new Date(a.date) - new Date(b.date);
    });

    const handleCompletedChange = (id) => {
        const updatedNote = sortedNotes.find(note => note.id === id);
        if (!updatedNote) {
            return;
        }

        const updatedNoteWithCompleted = {...updatedNote, completed: !updatedNote.completed};

        handleSave(updatedNoteWithCompleted);
    };

    const handleCheckboxChange = () => {
        setByUser(!byUser);
    };

    const openAddModal = () => {
        setCurrentNote(null);
        setModalOpen(true);
    }

    const openEditModal = (note) => {
        setCurrentNote(note);
        setModalOpen(true);
    };

    const closeModal = () => {
        setCurrentNote(null);
        setModalOpen(false);
    };

    const handleSave = (note) => {
        fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/note`, {
            method: note.id ? 'PUT' : 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(note),
        }).then(response => handleResponse(response))
            .then(() => fetchNotes());
    };

    const remove = (id) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar esta nota?');

        if (confirmDelete) {
            fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/note/${id}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(response => handleResponse(response))
                .then(() => fetchNotes());
        }
    }

    if (loading) {
        return <Spinner/>;
    }

    const groupedNotes = sortedNotes.reduce((acc, note) => {
        const date = new Date(note.date).toDateString();
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(note);
        return acc;
    }, {});

    return (
        <>
            {!minimized && (
                <div className="notes-container">
                    <div>
                        <div className="col d-flex justify-content-center">
                            <h4>Tareas</h4>
                            <div className="col d-flex justify-content-center">
                                <Input
                                    type="checkbox"
                                    id="byUserCheckbox"
                                    checked={byUser}
                                    onChange={handleCheckboxChange}
                                />
                                <Label for="byUserCheckbox" className="ms-2">
                                    Mis tareas
                                </Label>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faChevronUp} onClick={toggleMinimize} className="minimize-icon"/>
                            </div>
                        </div>
                        <div>
                            <Button size="sm" color="primary" onClick={openAddModal} className="add-button">
                                <FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                    </div>
                    <ul className="notes-list">
                        {Object.entries(groupedNotes).map(([date, notesForDate], index) => (
                            <React.Fragment key={date}>
                                {index === 0 || Object.keys(groupedNotes)[index - 1] !== date ? (
                                    <h5 className="note-date">{new Date(date).toLocaleDateString()}</h5>
                                ) : null}
                                {notesForDate.map(note => (
                                    <li key={note.id} className="note-item">
                                        <Input
                                            type="checkbox"
                                            checked={note.completed}
                                            onChange={() => handleCompletedChange(note.id)}
                                            className="note-checkbox"
                                        />
                                        <div
                                            className="note-content"
                                            style={{
                                                textDecoration: note.completed ? 'line-through' : 'none',
                                            }}
                                        >
                                            <div className="note-header">
                                                <h4 className="note-title">{byUser ? '' : note.userId + ' - '}{note.title}</h4>
                                                <ButtonGroup className="action-buttons">
                                                    <Button
                                                        color="warning"
                                                        size="sm"
                                                        onClick={() => openEditModal(note)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        onClick={() => remove(note.id)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                            <p className="note-description">{note.description}</p>

                                        </div>
                                    </li>
                                ))}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            )}
            {minimized && (
                <div className="maximize-button">
                    <FontAwesomeIcon icon={faChevronDown} onClick={toggleMinimize} className="maximize-icon"/>
                </div>
            )}
            <NoteModal
                isOpen={modalOpen}
                toggle={closeModal}
                handleSave={handleSave}
                initialNote={currentNote}
            />
        </>
    );
};

NoteList.propTypes = {
    minimized: PropTypes.bool,
    toggleMinimize:
    PropTypes.func
};

export default NoteList;
