import React, {useState} from 'react';
import {
    Collapse,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink
} from 'reactstrap';
import {Link} from 'react-router-dom';
import {useAuth} from "../auth/AuthContext";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faSignInAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import './AppNavbar.css';
import NavbarUser from "./NavbarUser";

const AppNavbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const {authenticated, user} = useAuth();

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <Navbar id="appNavbar" color="dark" dark expand="md">
            {authenticated && (
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} nav inNavbar>
                    <DropdownToggle nav caret>
                        <FontAwesomeIcon icon={faBars} className="menu-icon"/>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem tag="a" href="/budgets" target="_self">Expedientes</DropdownItem>
                        <DropdownItem tag="a" href="/plannings" target="_self">Planificación</DropdownItem>
                        <DropdownItem tag="a" href="/customers" target="_self">Clientes</DropdownItem>
                        <DropdownItem tag="a" href="/agenda" target="_self">Agenda</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            )}
            <NavbarBrand tag={Link} to="/">TRAVELCAT APP</NavbarBrand>
            <NavbarToggler onClick={handleToggle}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="justify-content-end" style={{width: "100%"}} navbar>
                    <NavItem>
                        <NavLink href="https://www.travelcat.es/">travelcat.es</NavLink>
                    </NavItem>
                    <NavItem>
                        {authenticated ? (
                            <NavLink tag={Link} to="/logout">
                                <FontAwesomeIcon icon={faSignOutAlt}/>
                            </NavLink>
                        ) : (
                            <NavLink tag={Link} to="/login">
                                <FontAwesomeIcon icon={faSignInAlt}/>
                            </NavLink>
                        )}
                    </NavItem>
                    {user &&
                        <NavbarUser username={user}/>
                    }
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default AppNavbar;
