const TechnicalModel = {
    id: '',
    version: '',
    createdBy: '',
    creationTimestamp: '',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '',
};

export function getTechnicalModel() {
    return TechnicalModel;
}
