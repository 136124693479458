import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {NavItem} from 'reactstrap';
import PropTypes from "prop-types";
import './NavbarUser.css';

const NavbarUser = ({username}) => {
    return (
        <NavItem className="navbar-user d-flex align-items-center text-white" style={{cursor: 'pointer'}}>
            <div className="d-flex flex-column align-items-center">
                <FontAwesomeIcon icon={faUser} className="user-icon"/>
                <span className="username">{username}</span>
            </div>
        </NavItem>
    );
};

NavbarUser.propTypes = {
    username: PropTypes.string.isRequired
};

export default NavbarUser;
