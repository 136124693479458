import React, {useEffect, useRef} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {useAuth} from "../auth/AuthContext";
import Home from "../Home";

const Logout = () => {
    const {logout} = useAuth();
    const navigate = useNavigate();
    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        // Invoke the logout function
        logout(navigate)
            .then(() => {
                // Additional logic after logout, if needed
                console.log('Logout successful');
            })
            .catch((error) => {
                // Handle any errors
                console.error('Logout failed:', error);
            });

        // Cleanup: Set the flag to false when the component unmounts
        return () => {
            isMounted.current = false;
        };
    }, [logout, navigate]);

    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
        </Routes>
    );

};

export default Logout;
