import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Container, Input, Modal, ModalBody, ModalFooter, Table} from 'reactstrap';
import {Link, useParams} from 'react-router-dom';
import '../../common/Common.css';
import './BudgetDetailList.css';
import {fetchData, handleResponse} from "../../http/HttpUtil";
import {getBudgetModel} from "../BudgetModel";
import {
    getBudgetDetailEndpoints,
    getBudgetDetailType,
    getBudgetDetailTypeLists,
    getItemStatusAbbreviation,
    getPaymentCategories,
    getPaymentTypeKey,
    getTaskCategories,
    getTaskModel
} from "./BudgetDetailModel";
import {
    calculateNumberOfPeople,
    formatBooleanUppercase,
    formatTimestampToDate,
    formatTimestampToDateTime,
    getKey
} from "../../common/Utils";
import AuditResponseWidget from "../audit/AuditResponseWidget";
import Attachment from "../../attachment/Attachment";
import Spinner from "../../common/Spinner";
import LinkCustomers from "../LinkCustomers";

const BudgetDetailList = () => {
    const [budgetDetail, setBudgetDetail] = useState(getBudgetModel);
    const [loading, setLoading] = useState(false);
    const [isAccountingEditable, setIsAccountingEditable] = useState(false);
    const [isExtraEditable, setIsExtraEditable] = useState(false);
    const [isFinancingEditable, setIsFinancingEditable] = useState(false);
    const [openAudit, setOpenAudit] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [editedAccountingFields, setEditedAccountingFields] = useState({});
    const [editedExtraFields, setEditedExtraFields] = useState({});
    const [editedFinancingFields, setEditedFinancingFields] = useState({});
    const [editedTaskFields, setEditedTaskFields] = useState([]);
    const [showLinkCustomers, setShowLinkCustomers] = useState(false);
    const [linkCustomersExpedientCustomerIds, setLinkCustomersExpedientCustomerIds] = useState(null);
    const {id} = useParams();

    const itemStatusAbbreviation = getItemStatusAbbreviation();
    const paymentTypeKeys = getPaymentTypeKey();
    const budgetDetailTypes = getBudgetDetailType();
    const typeLists = getBudgetDetailTypeLists();
    const budgetDetailEndpoints = getBudgetDetailEndpoints();
    const taskCategories = getTaskCategories();

    const convertSubTypeToList = (subTypeData, subType) => {
        if (!subTypeData?.[subType]?.[subType]) {
            return [];
        }

        const subTypeArray = Array.isArray(subTypeData[subType][subType])
            ? subTypeData[subType][subType]
            : [subTypeData[subType][subType]];

        const typeKey = getKey(subType, typeLists);

        return subTypeArray.map((item) => {
            return {
                id: item.id || '',
                itemStatus: getItemStatus(subType, item),
                type: budgetDetailTypes[typeKey],
                dateTime: item.dateTime || '',
                deliveryDateTime: item.deliveryDateTime || '',
                description: getDescriptionByType(subType, item),
                supplier: item.supplier || '',
                price: item.price || '',
                excludeFromPrice: item.excludeFromPrice || '',
                notes: item.notes || '',
                reservationCode: item.reservationCode || '',
                splits: item.splits || '',
            };
        });
    };

    const getItemStatusStyle = (itemStatus) => {
        const statusStyles = {
            [itemStatusAbbreviation.BOOKED]: {backgroundColor: 'green', color: 'white'},
            [itemStatusAbbreviation.PAYED]: {backgroundColor: 'yellow', color: 'black'},
            [itemStatusAbbreviation.CANCELLED]: {backgroundColor: 'red', color: 'white'},
        };
        for (const status in statusStyles) {
            if (itemStatus?.startsWith(status)) {
                return statusStyles[status];
            }
        }
        return {};
    };

    function getItemStatus(type, item) {
        const abbreviatedItemStatus = itemStatusAbbreviation[item.itemStatus];
        if (type === typeLists.FLIGHT
            && !!item.reservationUntil
            && abbreviatedItemStatus === itemStatusAbbreviation.BOOKED) {
            return `${abbreviatedItemStatus} (hasta el ${formatTimestampToDateTime(item.reservationUntil)})`
        }
        return abbreviatedItemStatus;
    }

    function getDateTime(type, detail) {
        if (type === budgetDetailTypes.FLIGHT
            && !!detail.dateTime) {
            return formatTimestampToDate(detail.dateTime);
        }
        if (type === budgetDetailTypes.ACCOMMODATION
            && !!detail.dateTime
            && !!detail.deliveryDateTime) {
            return `${formatTimestampToDate(detail.dateTime)}-${formatTimestampToDate(detail.deliveryDateTime)}`
        }
        const dateTime = formatTimestampToDateTime(detail.dateTime);
        if (type === budgetDetailTypes.CAR_RENT
            && !!dateTime
            && !!detail.deliveryDateTime) {
            return `${dateTime}-${formatTimestampToDateTime(detail.deliveryDateTime)}`
        }
        return dateTime;
    }

    function getFlightDescription(item) {
        return `${item.itinerary || ''} | ${item.company || ''} | ${item.reservationCode || ''}`;
    }

    function getAccommodationDescription(item) {
        return (
            <span>{`${item.nights || '0'} | ${item.destination || ''} | ${item.name || ''} | ${item.regime || ''} | ${item.room || ''} | ${item.stars || ''}* | `}
                {item.link ? <a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a> : ''}</span>
        );
    }

    function getCarRentDescription(item) {
        return `${item.origin || ''} | ${item.destination || ''} | ${item.carModel || ''}`;
    }

    function getTransferDescription(item) {
        return `${item.origin || ''} | ${item.destination || ''}`;
    }

    function getActivityDescription(item) {
        return `${item.name || ''} | ${'PVP: ' + item.pvp || ''} | ${'Beneficio: ' + item.benefit || ''}`;
    }

    function getDescriptionByType(type, item) {
        if (type === typeLists.FLIGHT) {
            return getFlightDescription(item);
        } else if (type === typeLists.ACCOMMODATION) {
            return getAccommodationDescription(item);
        } else if (type === typeLists.TRANSFER) {
            return getTransferDescription(item);
        } else if (type === typeLists.CAR_RENT) {
            return getCarRentDescription(item);
        } else if (type === typeLists.ACTIVITY) {
            return getActivityDescription(item);
        }
        return '';
    }

    const remove = async (type, id) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este detalle?');

        if (confirmDelete) {
            const typeKey = getKey(type, budgetDetailTypes);
            const budgetDetailEndpoint = budgetDetailEndpoints[typeKey];
            await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/${budgetDetailEndpoint}/${id}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then(response => handleResponse(response))
                .then(() => window.location.reload());
        }
    }

    useEffect(() => {
        setLoading(true);
        fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/budget/${id}`, {})
            .then(response => handleResponse(response))
            .then(data => {
                setBudgetDetail(data);
                setEditedAccountingFields({
                    pvp: data.accounting.pvp,
                    pricePerFamily: data.accounting.pricePerFamily,
                    pricePerFamilyEnabled: data.accounting.pricePerFamilyEnabled,
                    total: data.accounting.total
                });
                setEditedExtraFields({
                    forestPrice: data.extra.forestPrice,
                    insurancePrice: data.extra.insurancePrice,
                    giftPrice: data.extra.giftPrice,
                    cancellationInsurance: data.extra.cancellationInsurance,
                    extras: data.extra.extras?.map(extra => ({
                        id: extra.id,
                        extraType: extra.extraType,
                        price: extra.price
                    })) || [],
                });
                setEditedFinancingFields({
                    comments: data.financing.comments,
                    schedule: data.financing.schedule,
                    signed: data.financing.signed
                });
                setEditedTaskFields(data.tasks);
                setLoading(false);
            });
    }, [id, setBudgetDetail]);

    const mainSubTypes = [typeLists.FLIGHT, typeLists.ACCOMMODATION, typeLists.TRANSFER, typeLists.CAR_RENT, typeLists.ACTIVITY];

    function getDetails(isAlternative) {
        return mainSubTypes.reduce((acc, subType) => {
            return acc.concat(convertSubTypeToList(budgetDetail, subType));
        }, []).filter(item => {
            return (isAlternative && !!item?.excludeFromPrice) ||
                (!isAlternative && !item?.excludeFromPrice);
        });
    }

    function getSortedDetails(isAlternative) {
        return [...getDetails(isAlternative)].sort((a, b) => {
            const dateTimeA = a.splits && a.splits.length > 0 ? new Date(a.splits[0].date).getTime() : new Date(a.dateTime).getTime();
            const dateTimeB = b.splits && b.splits.length > 0 ? new Date(b.splits[0].date).getTime() : new Date(b.dateTime).getTime();

            return dateTimeA - dateTimeB;
        });
    }

    if (loading) {
        return <Spinner/>;
    }

    function getTitleAndAddBudgetDetailButton() {
        return <div className="d-flex justify-content-between align-items-center">
            <h2>Detalle de presupuesto - {budgetDetail.expedient.travelcatId} - {budgetDetail.title}</h2>
            <div className="float-end">
                <ButtonGroup>
                    <Button size="sm" color="warning"
                            onClick={() => handleShowAttachment()}>Ficheros</Button>
                    {showAttachment && (
                        <Attachment
                            entity='expedient'
                            id={budgetDetail.expedient.id}
                            onClose={() => setShowAttachment(false)}
                        />
                    )}
                    <Button size="sm" color="success"
                            onClick={() => handleAuditButtonClick()}>Registros</Button>
                    {openAudit && (
                        <AuditResponseWidget
                            entity='budget'
                            id={budgetDetail.id}
                            onClose={() => setOpenAudit(false)}
                        />
                    )}
                    <Button size="sm" color="danger" disabled>Exportar a PDF (en desarrollo)</Button>
                    <Button color="primary" tag={Link} to={"/budgets/" + budgetDetail.id + "/details/new"}>Añadir
                        servicio</Button>
                </ButtonGroup>
            </div>
        </div>;
    }

    function getCaseOpeningTable() {
        return <div className="table-container">
            <Table className="mt-4">
                <thead>
                <tr>
                    <th width="15%">Cliente(s)</th>
                    <th width="6%">Nº Personas</th>
                    <th width="8%">Teléfono</th>
                    <th width="8%">Email</th>
                    <th width="9%">Ciudad Origen</th>
                    <th width="15%">Disponibilidad</th>
                    <th width="15%">Viaje de interés</th>
                    <th width="9%">Vía de entrada</th>
                    <th width="8%">Comentarios</th>
                    <th width="7%"></th>
                </tr>
                </thead>
                <tbody>
                {loading ? (
                    <tr>
                        <Spinner/>
                    </tr>
                ) : (
                    <tr>
                        <td>{budgetDetail.customers}</td>
                        <td>{calculateNumberOfPeople(budgetDetail.people)} ({
                            (budgetDetail.people?.numberOfAdults || 0)}A/{
                            (budgetDetail.people?.kids?.length || 0)}N/{
                            (budgetDetail.people?.numberOfBabies || 0)}B)
                        </td>
                        <td>{budgetDetail.expedient.caseOpening.phone}</td>
                        <td>{budgetDetail.expedient.caseOpening.email}</td>
                        <td>{budgetDetail.expedient.caseOpening.fromCity}</td>
                        <td>{budgetDetail.expedient.caseOpening.availability}</td>
                        <td>{budgetDetail.expedient.caseOpening.tripOfInterest}</td>
                        <td>{budgetDetail.expedient.caseOpening.entrypoint}</td>
                        <td>{budgetDetail.expedient.caseOpening.comments}</td>
                        <td>
                            <Button size="sm" color="warning" tag={Link}
                                    to={"/budgets/" + budgetDetail.id}>Editar</Button>
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>;
    }

    const handleShowLinkCustomers = (expedient) => {
        setLinkCustomersExpedientCustomerIds(expedient.customers?.map(customer => customer.id) || []);
        setShowLinkCustomers(true);
    };

    const handleCloseLinkCustomers = () => {
        setLinkCustomersExpedientCustomerIds(null);
        setShowLinkCustomers(false);
    };

    function getLinkCustomerModal() {
        return <>
            {showLinkCustomers && (
                <Modal isOpen={showLinkCustomers} toggle={() => handleCloseLinkCustomers()}
                       size="xl">
                    <ModalBody>
                        <LinkCustomers
                            expedientId={budgetDetail.expedient.id}
                            travelcatId={budgetDetail.expedient.travelcatId}
                            initialCustomerIds={linkCustomersExpedientCustomerIds}
                            onClose={() => handleCloseLinkCustomers()}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger"
                                onClick={() => handleCloseLinkCustomers()}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>;
    }

    function getCustomersTable() {
        return (
            <div className="table-container">
                <Table className="mt-4">
                    <thead>
                    <tr>
                        <th colSpan="5">
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h5>Clientes asignados</h5>
                                <div>
                                    <Button size="sm" color="warning"
                                            onClick={() => handleShowLinkCustomers(budgetDetail.expedient)}>
                                        Vincular clientes
                                    </Button>
                                    {getLinkCustomerModal()}
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th width="20%">Nombre y apellidos</th>
                        <th width="20%">Teléfono</th>
                        <th width="20%">Email</th>
                        <th width="20%">DNI</th>
                        <th width="20%">Pasaporte</th>
                    </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                        <tr>
                            <Spinner/>
                        </tr>
                    ) : (
                        budgetDetail.expedient.customers?.map((customer) => (
                            <tr key={customer.id}>
                                <td>{customer.firstName} {customer.firstSurname} {customer.secondSurname}</td>
                                <td>{customer.phone}</td>
                                <td>{customer.email}</td>
                                <td>{customer.idNumber}</td>
                                <td>{customer.passport}</td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </Table>
            </div>
        );
    }

    const handleExtraEdit = () => {
        setIsExtraEditable(true);
    };

    const handleAuditButtonClick = () => {
        setOpenAudit(true);
    };

    const handleShowAttachment = () => {
        setShowAttachment(true);
    };

    const handleExtraInputChange = (field, value) => {
        setEditedExtraFields({...editedExtraFields, [field]: value});
    };

    const handleExtraAccept = () => {
        handleDirectEditAccept({
            ...budgetDetail,
            extra: {
                ...budgetDetail.extra,
                ...editedExtraFields,
            },
        });
    };

    const handleExtraCancel = () => {
        setIsExtraEditable(false);
        setEditedExtraFields({
            forestPrice: budgetDetail.extra.forestPrice,
            insurancePrice: budgetDetail.extra.insurancePrice,
            giftPrice: budgetDetail.extra.giftPrice,
            cancellationInsurance: budgetDetail.extra.cancellationInsurance,
            extras: budgetDetail.extra.extras?.map(extra => ({
                id: extra.id,
                extraType: extra.extraType,
                price: extra.price
            })) || [],
        });
    };

    const handleAddExtra = () => {
        setEditedExtraFields(prevState => ({
            ...prevState,
            extras: [
                ...prevState.extras,
                {extraType: '', price: ''}
            ]
        }));
    };

    const handleRemoveExtra = (indexToRemove) => {
        setEditedExtraFields(prevState => ({
            ...prevState,
            extras: prevState.extras.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handlePriceChange = (index, value) => {
        setEditedExtraFields(prevState => ({
            ...prevState,
            extras: prevState.extras.map((item, i) => i === index ? {...item, price: value} : item)
        }));
    };

    const handleExtraTypeChange = (index, value) => {
        setEditedExtraFields(prevState => ({
            ...prevState,
            extras: prevState.extras.map((item, i) => i === index ? {...item, extraType: value} : item)
        }));
    };

    function getExtraTable() {
        return <div className="table-container">
            <Table className="mt-4">
                <tbody>
                {loading ? (
                    <tr>
                        <Spinner/>
                    </tr>
                ) : (
                    <tr>
                        <td width="1%"></td>
                        <td width="15%">Bosque: {isExtraEditable ?
                            <input
                                type="text"
                                value={editedExtraFields?.forestPrice}
                                onChange={(e) => handleExtraInputChange('forestPrice', e.target.value)}
                            /> :
                            budgetDetail.extra.forestPrice}€
                        </td>
                        <td width="15%">Seguro: {isExtraEditable ?
                            <input
                                type="text"
                                value={editedExtraFields?.insurancePrice}
                                onChange={(e) => handleExtraInputChange('insurancePrice', e.target.value)}
                            /> :
                            budgetDetail.extra.insurancePrice}€
                        </td>
                        <td width="15%">Regalo: {isExtraEditable ?
                            <input
                                type="text"
                                value={editedExtraFields?.giftPrice}
                                onChange={(e) => handleExtraInputChange('giftPrice', e.target.value)}
                            /> :
                            budgetDetail.extra.giftPrice}€
                        </td>
                        <td width="20%">Seguro de cancelación: {isExtraEditable ?
                            <input
                                type="text"
                                value={editedExtraFields?.cancellationInsurance}
                                onChange={(e) => handleExtraInputChange('cancellationInsurance', e.target.value)}
                            /> :
                            budgetDetail.extra.cancellationInsurance}€
                        </td>
                        <td width="18%">Otros: {isExtraEditable ? (
                            <div>
                                {editedExtraFields.extras?.map((extra, index) => (
                                    <div key={`extra_${index}`} className="extra-row">
                                        <input
                                            type="text"
                                            placeholder="Tipo de extra"
                                            value={extra.extraType}
                                            onChange={(e) => handleExtraTypeChange(index, e.target.value)}
                                            className="extra-input"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Precio"
                                            value={extra.price}
                                            onChange={(e) => handlePriceChange(index, e.target.value)}
                                            className="extra-input extra-price-input"
                                        />
                                        <Button color="danger" onClick={() => handleRemoveExtra(index)}>-</Button>
                                    </div>
                                ))}
                                <Button color="success" onClick={handleAddExtra}>+</Button>
                            </div>
                        ) : (
                            <div>
                                {budgetDetail.extra.extras?.map((extra, index) => (
                                    <div key={`extra_${index}`} className="extra-row">
                                        {extra.extraType}: {extra.price}€
                                    </div>
                                ))}
                            </div>
                        )}
                        </td>
                        <td width="7%">
                            {isExtraEditable ? (
                                <ButtonGroup>
                                    <Button size="sm" color="success" onClick={handleExtraAccept}>Aceptar</Button>
                                    <Button size="sm" color="danger" onClick={handleExtraCancel}>Cancelar</Button>
                                </ButtonGroup>
                            ) : (
                                <Button size="sm" color="warning" onClick={handleExtraEdit}>Editar</Button>
                            )}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    }

    const handleAccountingEdit = () => {
        setIsAccountingEditable(true);
    };

    const handleAccountingFieldsCancel = () => {
        setIsAccountingEditable(false);
        setEditedAccountingFields({
            pvp: budgetDetail.accounting.pvp,
            pricePerFamily: budgetDetail.accounting.pricePerFamilyEnabled,
            pricePerFamilyEnabled: budgetDetail.accounting.pricePerFamilyEnabled,
            total: budgetDetail.accounting.total
        });
    };

    const handleAccountingFieldsChange = (field, value) => {
        setEditedAccountingFields({...editedAccountingFields, [field]: value});
    };

    function calculateTotalAccounting() {
        if (editedAccountingFields.pricePerFamilyEnabled) {
            return budgetDetail.accounting.pricePerFamily !== editedAccountingFields.pricePerFamily ?
                editedAccountingFields.pricePerFamily :
                editedAccountingFields.total;
        }
        return budgetDetail.accounting.pvp !== editedAccountingFields.pvp ?
            editedAccountingFields.pvp * calculateNumberOfPeople(budgetDetail.people) :
            editedAccountingFields.total;
    }

    const handleAccountingFieldsAccept = () => {
        handleDirectEditAccept({
            ...budgetDetail,
            accounting: {
                ...budgetDetail.accounting,
                ...editedAccountingFields,
                pvp: editedAccountingFields.pricePerFamilyEnabled ? 0 : editedAccountingFields.pvp,
                pricePerFamily: editedAccountingFields.pricePerFamilyEnabled ? editedAccountingFields.pricePerFamily : 0,
                total: calculateTotalAccounting(),
            },
        });
    };

    function handleDirectEditAccept(requestBody) {
        fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/budget`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        }).then(response => handleResponse(response))
            .then(() => window.location.reload());
    }

    function getAccountingTable() {
        return <div className="table-container">
            <Table className="mt-4">
                <tbody>
                {loading ? (
                    <tr>
                        <Spinner/>
                    </tr>
                ) : (
                    <tr>
                        <td></td>
                        <td width="20%"><strong>NETO</strong>: {budgetDetail.accounting.price}€</td>
                        {!editedAccountingFields.pricePerFamilyEnabled && (
                            <td width="20%">
                                <strong>PVP</strong>: {isAccountingEditable ? (
                                <input
                                    type="text"
                                    value={editedAccountingFields.pvp}
                                    onChange={(e) => handleAccountingFieldsChange('pvp', e.target.value)}
                                />
                            ) : (
                                budgetDetail.accounting.pvp
                            )}€/PAX
                            </td>
                        )}
                        {editedAccountingFields.pricePerFamilyEnabled && (
                            <td width="20%">
                                <strong>Precio por
                                    familia</strong>: {isAccountingEditable ? (
                                <input
                                    type="text"
                                    value={editedAccountingFields.pricePerFamily}
                                    onChange={(e) => handleAccountingFieldsChange('pricePerFamily', e.target.value)}
                                />
                            ) : (
                                budgetDetail.accounting.pricePerFamily
                            )}€
                            </td>
                        )}
                        <td width="13%">
                            <div>
                                <span>Por familia</span>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    disabled={!isAccountingEditable}
                                    checked={editedAccountingFields.pricePerFamilyEnabled}
                                    onChange={(e) => handleAccountingFieldsChange('pricePerFamilyEnabled', e.target.checked)}
                                />
                            </div>
                        </td>
                        <td width="20%">
                            <strong>TOTAL</strong>: {isAccountingEditable ? (
                            <input
                                type="text"
                                value={editedAccountingFields.total}
                                onChange={(e) => handleAccountingFieldsChange('total', e.target.value)}
                            />
                        ) : (
                            budgetDetail.accounting.total
                        )}€
                        </td>
                        <td width="20%"><strong>BENEFICIO</strong>: {budgetDetail.accounting.benefit}€</td>
                        <td width="7%">
                            {isAccountingEditable ? (
                                <>
                                    {'  '}
                                    <ButtonGroup>
                                        <Button size="sm" color="success"
                                                onClick={handleAccountingFieldsAccept}>Aceptar</Button>
                                        <Button size="sm" color="danger"
                                                onClick={handleAccountingFieldsCancel}>Cancelar</Button>
                                    </ButtonGroup>
                                </>
                            ) : (
                                <>
                                    {'  '}
                                    <Button size="sm" color="warning" onClick={handleAccountingEdit}>Editar</Button>
                                </>
                            )}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
            ;
    }

    const handleFinancingEdit = () => {
        setIsFinancingEditable(true);
    };

    const handleFinancingInputChange = (field, value) => {
        setEditedFinancingFields({...editedFinancingFields, [field]: value});
    };

    const handleFinancingAccept = () => {
        handleDirectEditAccept({
            ...budgetDetail,
            financing: {
                ...budgetDetail.financing,
                ...editedFinancingFields,
            },
        });
    };

    const handleFinancingCancel = () => {
        setIsFinancingEditable(false);
        setEditedFinancingFields({
            comments: budgetDetail.financing.comments,
            schedule: budgetDetail.financing.schedule,
            signed: budgetDetail.financing.signed
        });
    };

    const handlePaymentStatusChange = (financingPayment, checked) => {
        financingPayment.status = checked;
        fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/financing`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(financingPayment),
        }).then(response => handleResponse(response))
            .then(() => window.location.reload());
    };

    function getFinancingTable() {
        const {totalPrice, financingPayments, comments, schedule, signed} = budgetDetail.financing;

        return (
            <div style={{width: '45%', float: 'left', marginLeft: '10%'}}>
                <table style={{width: '80%', borderCollapse: 'collapse', border: '1px solid black'}}>
                    <tbody>
                    <tr>
                        <td colSpan="4"
                            style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                            <strong>FINANCIACIÓN</strong>: {totalPrice}€
                        </td>
                        <td colSpan="1"
                            style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                            <Button size="sm" color="primary" tag={Link}
                                    to={`/budgets/${budgetDetail.id}/details/new?type=${getKey(budgetDetailTypes.FINANCING, budgetDetailTypes)}`}>
                                Añadir</Button>
                        </td>
                    </tr>
                    {financingPayments?.slice()
                        .sort((a, b) => a.position - b.position)
                        .map((payment) => (
                            <tr key={payment.id}>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>{payment.position}o
                                </td>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>{formatTimestampToDate(payment.date)}</td>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>{payment.total}€
                                </td>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>
                                    <input
                                        type="checkbox"
                                        checked={payment.status}
                                        onChange={(e) => handlePaymentStatusChange(payment, e.target.checked)}
                                    />
                                </td>
                                <td style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                                    <ButtonGroup>
                                        <Button size="sm" color="warning" tag={Link}
                                                to={`/budgets/${budgetDetail.id}/details/${payment.id}?type=${getKey(budgetDetailTypes.FINANCING, budgetDetailTypes)}`}>Editar</Button>
                                        <Button size="sm" color="danger"
                                                onClick={() => remove(budgetDetailTypes.FINANCING, payment.id)}>Eliminar</Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                    <tr>
                        <td colSpan="4"
                            style={{border: '1px solid black', padding: '8px', textAlign: 'left'}}>
                            <strong>Observaciones</strong>: {isFinancingEditable ?
                            <input
                                type="text"
                                value={editedFinancingFields?.comments}
                                onChange={(e) => handleFinancingInputChange('comments', e.target.value)}
                            /> :
                            comments}
                        </td>
                        <td colSpan="1"
                            style={{padding: '8px', textAlign: 'center'}}>
                            {isFinancingEditable ? (
                                <ButtonGroup>
                                    <Button size="sm" color="success" onClick={handleFinancingAccept}>Aceptar</Button>
                                    <Button size="sm" color="danger" onClick={handleFinancingCancel}>Cancelar</Button>
                                </ButtonGroup>
                            ) : (
                                <Button color="warning" onClick={handleFinancingEdit}>Editar</Button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" style={{border: '1px solid black', padding: '8px'}}>
                            <strong>AGENDAR</strong>: {isFinancingEditable ?
                            <input
                                type="checkbox"
                                checked={editedFinancingFields?.schedule}
                                onChange={(e) => handleFinancingInputChange('schedule', e.target.checked)}
                            /> :
                            formatBooleanUppercase(schedule)}
                        </td>
                        <td colSpan="2" style={{border: '1px solid black', padding: '8px'}}>
                            <strong>FIRMA CONTRATO</strong>: {isFinancingEditable ?
                            <input
                                type="checkbox"
                                checked={editedFinancingFields?.signed}
                                onChange={(e) => handleFinancingInputChange('signed', e.target.checked)}
                            /> :
                            formatBooleanUppercase(signed)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    function getTaskCategory(item) {
        if (!item) {
            return null;
        }
        return `${taskCategories[item.taskCategory] || item}`;
    }

    const handleAddTask = () => {
        setEditedTaskFields(prevState => ([
            ...prevState,
            getTaskModel()
        ]));
    };

    const handleTaskDelete = (index, taskId) => {
        const updatedTasks = [...editedTaskFields];
        updatedTasks.splice(index, 1);
        setEditedTaskFields(updatedTasks);
        if (taskId) {
            fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/task/${taskId}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then(response => handleResponse(response))
                .then(() => window.location.reload());
        }
    };

    const handleTaskCategoryChange = (index, category) => {
        const updatedTasks = [...editedTaskFields];
        updatedTasks[index].taskCategory = category;
        setEditedTaskFields(updatedTasks);
    };

    const handleTaskStatusChange = (index, status) => {
        const updatedTasks = [...editedTaskFields];
        updatedTasks[index].status = status;
        setEditedTaskFields(updatedTasks);
    };

    const handleTaskCheck = (task, checked) => {
        task.status = checked;
        fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/task`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(task),
        }).then(response => handleResponse(response))
            .then(() => window.location.reload());
    };

    const handleTaskAccept = () => {
        budgetDetail.tasks = editedTaskFields;
        fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/budget`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(budgetDetail),
        }).then(response => handleResponse(response))
            .then(() => window.location.reload());
    };

    function getTaskTable() {
        return (
            <div className="d-flex align-content-end justify-content-end">
                <div style={{width: '90%', float: 'right'}}>
                    <table style={{width: '99%', borderCollapse: 'collapse', border: '1px solid black'}}>
                        <tbody>
                        {editedTaskFields?.reduce((pairs, item, index) => {
                            if (index % 2 === 0) {
                                pairs.push([item]);
                            } else {
                                pairs[pairs.length - 1].push(item);
                            }
                            return pairs;
                        }, []).map((pair, index) => (
                            <tr key={`${pair[0]?.id}-${pair[1]?.id}`}>
                                <td style={{border: '1px solid black', padding: '8px', width: '50%'}}>
                                    {index < 5 ? (
                                        <>
                                            <a href={`/budgets/${budgetDetail.id}/details/${pair[0]?.id}?type=${getKey(budgetDetailTypes.TASK, budgetDetailTypes)}`}>
                                                <strong>{getTaskCategory(pair[0])}</strong>
                                            </a>
                                            {' '}
                                            <Input
                                                type="checkbox"
                                                checked={pair[0]?.status}
                                                onChange={(e) => handleTaskCheck(pair[0], e.target.checked)}
                                            />
                                        </>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Input
                                                type="text"
                                                value={pair[0]?.taskCategory}
                                                style={{width: '50%'}}
                                                onChange={(e) => handleTaskCategoryChange(index * 2, e.target.value)}
                                                autoComplete="taskCategory"
                                            />
                                            <Input
                                                type="checkbox"
                                                checked={pair[0]?.status}
                                                onChange={(e) => {
                                                    pair[0].id ? handleTaskCheck(pair[0], e.target.checked) : handleTaskStatusChange(index * 2, e.target.checked)
                                                }}
                                            />
                                            <ButtonGroup>
                                                <Button color="success" size="sm"
                                                        onClick={() => handleTaskAccept()}>Guardar</Button>
                                                <Button color="danger" size="sm"
                                                        onClick={() => handleTaskDelete(index * 2, pair[0].id)}>Eliminar</Button>
                                            </ButtonGroup>
                                        </div>
                                    )}
                                </td>
                                <td style={{border: '1px solid black', padding: '8px', width: '50%'}}>
                                    {index < 5 ? (
                                        <>
                                            <a href={`/budgets/${budgetDetail.id}/details/${pair[1]?.id}?type=${getKey(budgetDetailTypes.TASK, budgetDetailTypes)}`}>
                                                <strong>{getTaskCategory(pair[1])}</strong>
                                            </a>
                                            {' '}
                                            <Input
                                                type="checkbox"
                                                checked={pair[1]?.status}
                                                onChange={(e) => handleTaskCheck(pair[1], e.target.checked)}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {pair[1] && (
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Input
                                                        type="text"
                                                        value={pair[1]?.taskCategory}
                                                        style={{width: '50%'}}
                                                        onChange={(e) => handleTaskCategoryChange(index * 2 + 1, e.target.value)}
                                                        autoComplete="taskCategory"
                                                    />
                                                    <Input
                                                        type="checkbox"
                                                        checked={pair[1]?.status}
                                                        onChange={(e) => {
                                                            pair[1].id ? handleTaskCheck(pair[1], e.target.checked) : handleTaskStatusChange(index * 2 + 1, e.target.checked)
                                                        }}
                                                    />
                                                    <ButtonGroup>
                                                        <Button color="success" size="sm"
                                                                onClick={() => handleTaskAccept()}>Guardar</Button>
                                                        <Button color="danger" size="sm"
                                                                onClick={() => handleTaskDelete(index * 2 + 1, pair[1].id)}>Eliminar</Button>
                                                    </ButtonGroup>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <Button color="primary" size="sm" onClick={handleAddTask}>Añadir
                    </Button>
                </div>
            </div>
        );
    }

    function getIncidentTable() {
        return (
            <div>
                <Table className="mt-4">
                    <thead>
                    <tr>
                        <td width="8%" style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                            <strong>FECHA</strong>
                        </td>
                        <td width="80%" style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                            <strong>OBSERVACIONES / INCIDENCIAS</strong>
                        </td>
                        <td width="12%" style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                            <Button size="sm" color="primary" tag={Link}
                                    to={`/budgets/${budgetDetail.id}/details/new?type=${getKey(budgetDetailTypes.INCIDENT, budgetDetailTypes)}`}>
                                Añadir</Button>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {budgetDetail.incidents?.slice()
                        .sort((a, b) => a.date.localeCompare(b.date))
                        .map(incident => (
                            <tr key={incident.id}>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>{formatTimestampToDate(incident.date)}</td>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>{incident.comment}</td>
                                <td style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                                    <ButtonGroup>
                                        <Button size="sm" color="warning" tag={Link}
                                                to={`/budgets/${budgetDetail.id}/details/${incident.id}?type=${getKey(budgetDetailTypes.INCIDENT, budgetDetailTypes)}`}>Editar</Button>
                                        <Button size="sm" color="danger"
                                                onClick={() => remove(budgetDetailTypes.INCIDENT, incident.id)}>Eliminar</Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    function getPaymentsSort(a, b) {
        if (a.position === b.position) {
            if (a.paymentType === paymentTypeKeys.PAYMENT && b.paymentType !== paymentTypeKeys.PAYMENT) return -1;
            if (a.paymentType !== paymentTypeKeys.PAYMENT && b.paymentType === paymentTypeKeys.PAYMENT) return 1;
            return 0;
        }
        return a.position - b.position;
    }

    function calculatePaymentTotal(payment, total) {
        if (payment.paymentType === paymentTypeKeys.REFUND) {
            return total - payment.total;
        }
        return total + payment.total;
    }

    function getPaymentTable() {
        return (
            <div>
                <Table className="mt-4">
                    <thead>
                    <tr>
                        <td colSpan="3" width="88%"
                            style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                            <strong>PAGOS</strong>
                        </td>
                        <td width="12%" style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                            <Button size="sm" color="primary" tag={Link}
                                    to={`/budgets/${budgetDetail.id}/details/new?type=${getKey(budgetDetailTypes.PAYMENT, budgetDetailTypes)}`}>
                                Añadir</Button>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {budgetDetail.payments?.slice()
                        .sort((a, b) => getPaymentsSort(a, b))
                        .map(payment => (
                            <tr key={payment.id}>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>{payment.paymentType === paymentTypeKeys.PAYMENT ? 'PAGO' : 'REEMBOLSO'} {payment.position}</td>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>{formatTimestampToDate(payment.date)}</td>
                                <td style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'center'
                                }}>
                                    <strong>{payment.paymentType === paymentTypeKeys.PAYMENT ? '' : '-'}{payment.total}€</strong>
                                    <div>
                                        {payment.splits?.map(paymentSplit => (
                                            <div key={paymentSplit.id}>
                                                {getPaymentCategories()[paymentSplit.paymentCategory]} - {paymentSplit.amount}€
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                                    <ButtonGroup>
                                        <Button size="sm" color="warning" tag={Link}
                                                to={`/budgets/${budgetDetail.id}/details/${payment.id}?type=${getKey(budgetDetailTypes.PAYMENT, budgetDetailTypes)}`}>Editar</Button>
                                        <Button size="sm" color="danger"
                                                onClick={() => remove(budgetDetailTypes.PAYMENT, payment.id)}>Eliminar</Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                    <tr>
                        <td style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'center'
                        }}>TOTAL
                        </td>
                        <td style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'center'
                        }}><em>Último pago: {' '}
                            {budgetDetail.payments && budgetDetail.payments.length > 0 && (
                                new Date(Math.max(...budgetDetail.payments.map(payment => new Date(payment.date)))).toLocaleDateString()
                            )}</em>
                        </td>
                        <td style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'center'
                        }}>
                            <em>{budgetDetail.payments.reduce((total, payment) => calculatePaymentTotal(payment, total), 0)}€</em>
                        </td>
                        <td style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'center'
                        }}><em>Queda para el total: {' '}
                            {budgetDetail.accounting.total - budgetDetail.payments.reduce((total, payment) => calculatePaymentTotal(payment, total), 0)}€
                        </em></td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    function getDateTimeBySplits(detail) {
        return <>
            {detail.splits && detail.splits.length > 0 ? (
                <div>
                    {detail.splits.map((split, index) => (
                        <div key={`split_date_${index}`}>
                            {formatTimestampToDate(split.date)}
                        </div>
                    ))}
                </div>
            ) : getDateTime(detail.type, detail)}
        </>;
    }

    function getDescriptionBySplits(detail) {
        return <>
            {detail.splits && detail.splits.length > 0 ? (
                <div>
                    {detail.splits.map((split, index) => (
                        <div key={`split_description_${index}`}>
                            {split.itinerary} | {split.company}
                        </div>
                    ))}
                </div>
            ) : detail.description}
        </>;
    }

    function getBudgetDetailsTable(isAlternative) {
        const sortedDetails = getSortedDetails(isAlternative);

        if (isAlternative && sortedDetails.length === 0) {
            return null;
        }

        function getConcatenatedDetailNotes(detail) {
            return detail.notes ? ' | ' + detail.notes : '';
        }

        return <div className="table-container">
            <Table className="mt-4">
                <thead>
                {isAlternative ? (
                    <tr>
                        <th>ALTERNATIVAS</th>
                    </tr>
                ) : (
                    <tr>
                        <th>SERVICIOS</th>
                    </tr>
                )}
                <tr>
                    <th width="12%">ESTADO</th>
                    <th width="8%">TIPO</th>
                    <th width="10%">FECHA</th>
                    <th width="26%">DESCRIPCIÓN</th>
                    <th width="9%">PROVEEDOR</th>
                    <th width="7%">PRECIO</th>
                    <th width="20%">NOTAS</th>
                    <th width="14%">ACCIONES</th>
                </tr>
                </thead>
                <tbody>
                {loading ? (
                    <tr>
                        <Spinner/>
                    </tr>
                ) : (
                    sortedDetails.map((detail) => (
                        <tr key={`${detail.type}_${detail.id}`}>
                            <td style={getItemStatusStyle(detail.itemStatus)}>{detail.itemStatus}</td>
                            <td>{detail.type}</td>
                            <td>{getDateTimeBySplits(detail)}</td>
                            <td>{getDescriptionBySplits(detail)}</td>
                            <td>
                                {detail.splits && detail.splits.length > 0 ? (
                                    <div>
                                        {detail.splits.map((split, index) => (
                                            <div key={`split_supplier_${index}`}>
                                                {split.supplier}
                                            </div>
                                        ))}
                                    </div>
                                ) : detail.supplier}
                            </td>
                            <td>{detail.price}€</td>
                            <td>
                                {detail.reservationCode ? (
                                    'CÓDIGO DE RESERVA: ' + detail.reservationCode + getConcatenatedDetailNotes(detail)
                                ) : detail.notes}
                            </td>
                            <td>
                                <ButtonGroup>
                                    <Button size="sm" color="warning" tag={Link}
                                            to={`/budgets/${budgetDetail.id}/details/${detail.id}?type=${getKey(detail.type, budgetDetailTypes)}`}>Editar</Button>
                                    <Button size="sm" color="danger"
                                            onClick={() => remove(detail.type, detail.id)}>Eliminar</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
        </div>;
    }

    return (
        <div>
            <Container fluid>
                {getTitleAndAddBudgetDetailButton()}
                {getCaseOpeningTable()}
                {getCustomersTable()}
                {getBudgetDetailsTable(false)}
                {getExtraTable()}
                {getAccountingTable()}
                {getBudgetDetailsTable(true)}
                {getPaymentTable()}
                {getIncidentTable()}
                {getFinancingTable()}
                {getTaskTable()}
            </Container>
        </div>
    );
};

export default BudgetDetailList;
