import React from 'react';
import './App.css';
import {Navigate} from 'react-router-dom';
import {useAuth} from "./auth/AuthContext";

const Home = () => {
    const {authenticated} = useAuth();

    return (
        <>
            {authenticated ? (
                <Navigate to="/budgets" replace/>
            ) : (
                <Navigate to="/login" replace/>
            )}
        </>
    );
}

export default Home;
