function doErrorAlert(errorMsg) {
    if (errorMsg === '"ERR_CANNOT_SET_SOLD_STATUS"') {
        errorMsg = 'No se puede cambiar el estado a VENDIDO. El número de clientes no coincide con el número total de personas.'
    }
    window.alert('Ha habido un error: ' + errorMsg);
}

export async function handleResponse(response) {
    if (response.status === 400) {
        let errorMsg = await response.text();
        doErrorAlert(errorMsg);
    } else if (!response.ok && response.type === 'cors') {
        localStorage.setItem('authentication_failure', 'true');
        window.location.href = '/logout';
    } else if (response.status === 401 || response.status === 403) {
        window.location.href = '/login';
    } else if (response.status !== 200) {
        doErrorAlert(response);
    } else if (response.headers.get('Content-Type')?.includes('application/json')) {
        return await response.json();
    }
    return null;
}

export function fetchData(url, options) {
    options = {
        ...options,
        credentials: 'include',
    };
    options.headers = {
        ...options.headers,
        'X-Requested-With': 'XMLHttpRequest',
    }
    // Check if accessToken is available in localStorage
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        // If accessToken is available, add Authorization header to options
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${accessToken}`,
        };
    }
    return fetch(url, options);
}
