// AuthContext.js
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from "prop-types";
import {auth, googleAuthProvider, signInWithPopup} from './FirebaseConfig';
import {fetchData, handleResponse} from "../http/HttpUtil";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const storedAuthenticated = localStorage.getItem('authenticated') === 'true';
    const [authenticated, setAuthenticated] = useState(storedAuthenticated);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const handleLoginSuccess = useCallback((navigate, username) => {
        localStorage.setItem('authenticated', 'true');
        localStorage.setItem('user', username);
        setAuthenticated(true);
        setUser(username);
        navigate('/');
    }, [setAuthenticated, setUser]);

    const handleLogout = useCallback((navigate) => {
        localStorage.setItem('authenticated', 'false');
        localStorage.removeItem('user');
        setAuthenticated(false);
        setUser(null);
        window.location.reload();
        navigate('/');
    }, [setAuthenticated, setUser]);

    const googleSignIn = useCallback(async (navigate) => {
        const authorize = (token, email) => {
            fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/authorize-google`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => handleResponse(response))
                .then(() => {
                    localStorage.setItem('accessToken', token);
                    handleLoginSuccess(navigate, email);
                })
                .catch(error => {
                    console.error('Error fetching notes:', error);
                    handleLogout(navigate);
                });
        };

        try {
            await signInWithPopup(auth, googleAuthProvider)
                .then((result) => {
                    const user = result.user;
                    const token = user?.accessToken;
                    const email = user?.email;
                    authorize(token, email);
                });
        } catch (error) {
            console.error(error.message);
            handleLogout(navigate);
        }
    }, [handleLoginSuccess, handleLogout]);

    const login = useCallback(async (username, password, navigate) => {
        try {
            const loginData = new URLSearchParams();
            loginData.append('username', username);
            loginData.append('password', password);
            const response = await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                mode: 'cors',
                body: loginData,
            });

            if (response.ok) {
                // Successful login
                handleLoginSuccess(navigate, username);
            } else {
                console.error('Login failed: ', response.statusText);
                handleLogout(navigate);
            }
        } catch (error) {
            console.error('Error during login:', error);
            handleLogout(navigate);
        }
    }, [handleLoginSuccess, handleLogout]);

    const logout = useCallback(async (navigate) => {
        try {
            const response = await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/logout`, {
                method: 'POST',
            });

            if (response.ok) {
                handleLogout(navigate);
            } else {
                console.error('Logout failed: ', response.statusText);
                handleLogout(navigate);
            }
        } catch (error) {
            console.error('Error during logout:', error);
            handleLogout(navigate);
        }
    }, [handleLogout]);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(storedUser);
        }
        setLoading(false);
    }, []);

    const contextValue = useMemo(() => {
        return {authenticated, user, login, logout, googleSignIn};
    }, [authenticated, user, login, logout, googleSignIn]); // Only recompute when authenticated changes

    return (
        <AuthContext.Provider value={contextValue}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
