import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {
    Button,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import {fetchData, handleResponse} from "../http/HttpUtil";
import {getBudgetModel, getBudgetStatus, getTripTypeDictionary} from "./BudgetModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

const BudgetEdit = () => {
    const initialFormState = getBudgetModel();

    const [budget, setBudget] = useState(initialFormState);
    const [travelcatLocationId, setTravelcatLocationId] = useState('B');
    const [numberOfAdults, setNumberOfAdults] = useState(0);
    const [numberOfBabies, setNumberOfBabies] = useState(0);
    const [kidAges, setKidAges] = useState([]);
    const [kidAgeDropdownOpen, setKidAgeDropdownOpen] = useState([]);
    const navigate = useNavigate();
    const {id} = useParams();
    const budgetStatuses = getBudgetStatus();

    useEffect(() => {
        if (id !== 'new') {
            fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/budget/${id}`, {})
                .then(response => handleResponse(response))
                .then(data => {
                    setBudget(data);
                    setNumberOfAdults(data.people?.numberOfAdults);
                    setNumberOfBabies(data.people?.numberOfBabies);
                    setKidAges(data.people?.kids?.map(kid => kid.age) || []);
                });
        }
    }, [id, setBudget, setNumberOfAdults, setNumberOfBabies, setKidAges]);

    const handleIncrementAdults = () => {
        setNumberOfAdults(prevCount => prevCount + 1);
        handleChange({target: {name: 'people.numberOfAdults', value: numberOfAdults + 1}});
    };

    const handleDecrementAdults = () => {
        if (numberOfAdults > 0) {
            setNumberOfAdults(prevCount => prevCount - 1);
            handleChange({target: {name: 'people.numberOfAdults', value: numberOfAdults - 1}});
        }
    };

    const handleIncrementBabies = () => {
        setNumberOfBabies(prevCount => prevCount + 1);
        handleChange({target: {name: 'people.numberOfBabies', value: numberOfBabies + 1}});
    };

    const handleDecrementBabies = () => {
        if (numberOfBabies > 0) {
            setNumberOfBabies(prevCount => prevCount - 1);
            handleChange({target: {name: 'people.numberOfBabies', value: numberOfBabies - 1}});
        }
    };

    const handleAddKid = () => {
        setKidAges([...kidAges, 2]); // Default age to 2
        handleChange({
            target: {
                name: 'people.kids',
                value: [...(budget.people.kids || []), {age: 2}]
            }
        });
        setKidAgeDropdownOpen([...kidAgeDropdownOpen, false]); // Default dropdown to closed
    };

    const handleRemoveKid = (index) => {
        const updatedKids = budget.people.kids.filter((_, i) => i !== index);
        handleChange({
            target: {
                name: 'people.kids',
                value: updatedKids
            }
        });
        setKidAges(prevAges => prevAges.filter((_, i) => i !== index));
        setKidAgeDropdownOpen(prevOpen => prevOpen.filter((open, i) => i !== index));
    };

    const handleKidAgeChange = (index, age) => {
        const updatedKids = [...budget.people.kids];
        updatedKids[index].age = age;
        handleChange({
            target: {
                name: 'people.kids',
                value: updatedKids
            }
        });
        const newKidAges = [...kidAges];
        newKidAges[index] = age;
        setKidAges(newKidAges);
    };

    const toggleKidAgeDropdown = (index) => {
        const newKidAgeDropdownOpen = [...kidAgeDropdownOpen];
        newKidAgeDropdownOpen[index] = !newKidAgeDropdownOpen[index];
        setKidAgeDropdownOpen(newKidAgeDropdownOpen);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;

        if (name.includes('expedient.')) {
            if (name.includes('expedient.caseOpening.')) {
                // Nested property inside expedient.caseOpening
                const nestedPropertyName = name.replace('expedient.caseOpening.', '');
                setBudget({
                    ...budget,
                    expedient: {
                        ...budget.expedient,
                        caseOpening: {
                            ...budget.expedient.caseOpening,
                            [nestedPropertyName]: value,
                        },
                    },
                });

            } else {
                // Nested property inside expedient
                const nestedPropertyName = name.replace('expedient.', '');
                setBudget({
                    ...budget,
                    expedient: {
                        ...budget.expedient,
                        [nestedPropertyName]: value,
                    },
                });
            }
        } else if (name.includes('people.')) {
            // Nested property inside expedient.caseOpening
            const nestedPropertyName = name.replace('people.', '');
            setBudget({
                ...budget,
                people: {
                    ...budget.people,
                    [nestedPropertyName]: value,
                },
            });

        } else {
            // Top-level property
            setBudget({
                ...budget,
                [name]: value,
            });
        }
    };

    const handleTravelcatLocationIdChange = (event) => {
        setTravelcatLocationId(event.target.value);
    };

    const handleGenerateExpedient = async () => {
        const response = await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/expedient/generate/${travelcatLocationId}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        const data = await handleResponse(response);

        setBudget({
            ...budget,
            expedient: {
                ...budget.expedient,
                id: data.id,
                travelcatId: data.travelcatId,
            },
        });
    };

    const handleValidateExpedient = async () => {
        if (!budget?.expedient?.travelcatId) {
            window.alert('Introduce un valor en Expediente');
            return;
        }

        const response = await fetchData(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/expedient/travelcatId/${budget.expedient.travelcatId}`,
            {}
        );
        const data = await handleResponse(response);

        if (data) {
            setBudget({
                ...budget,
                expedient: {
                    ...budget.expedient,
                    id: data.id,
                    travelcatId: data.travelcatId,
                    caseOpening: data.caseOpening,
                },
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/budget`, {
            method: budget.id ? 'PUT' : 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(budget),
        }).then(response => handleResponse(response));

        setBudget(initialFormState);
        navigate('/budgets');
    };

    const formTitle = <h2>{budget.id ? 'Editar presupuesto' : 'Añadir presupuesto'}</h2>;

    return (<div>
            <Container>
                {formTitle}
                <Form onSubmit={handleSubmit}>
                    <FormGroup className="row align-items-center">
                        <Label for="travelcatId">Expediente</Label>
                        <div className="col-md-2">
                            <Input
                                type="text"
                                name="expedient.travelcatId"
                                id="travelcatId"
                                value={budget.expedient.travelcatId || ''}
                                onChange={handleChange}
                                autoComplete="travelcatId"
                                disabled={!!budget.expedient.id}
                                required
                            />
                        </div>
                        <div className="col-md-1">
                            <Button
                                color="primary"
                                onClick={handleValidateExpedient}
                                disabled={!budget.expedient.travelcatId || !!budget.expedient.id}
                            >
                                Validar
                            </Button>
                        </div>
                        <div className="col-md-1">
                            <Button color="primary"
                                    onClick={handleGenerateExpedient}
                                    disabled={!!budget.expedient.id}>
                                Generar
                            </Button>
                        </div>
                        <div className="col-md-2">
                            <Input
                                type="select"
                                name="travelcatLocationId"
                                id="travelcatLocationId"
                                value={travelcatLocationId}
                                onChange={handleTravelcatLocationIdChange}
                                disabled={!!budget.expedient.id}
                            >
                                <option value="B">Barcelona</option>
                                <option value="V">Valencia</option>
                                <option value="M">Madrid</option>
                            </Input>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label for="title">Destino</Label>
                        <Input
                            type="text"
                            name="title"
                            id="title"
                            value={budget.title || ''}
                            onChange={handleChange}
                            autoComplete="title"
                            disabled={!budget.expedient.id}
                            required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="tripType">Tipo de viaje</Label>
                        <Input
                            type="select"
                            name="tripType"
                            id="tripType"
                            value={budget.tripType || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            required
                        >
                            <option value="">Selecciona un tipo</option>
                            {Object.entries(getTripTypeDictionary()).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {value}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="deliveryDate">Fecha de entrega</Label>
                        <Input
                            type="date"
                            name="deliveryDate"
                            id="deliveryDate"
                            value={budget.deliveryDate || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="status">Estado del presupuesto</Label>
                        <Input
                            type="select"
                            name="status"
                            id="status"
                            value={budget.status || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            required
                        >
                            <option value="">Selecciona un estado</option>
                            {Object.entries(budgetStatuses).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {value}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>

                    <div style={{display: 'flex', justifyContent: 'flex-start', gap: '20px'}}>
                        <FormGroup>
                            <Label for="numberOfAdults">Nº adultos</Label>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {budget.expedient.id && numberOfAdults > 1 ? (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        onClick={handleDecrementAdults}
                                        style={{cursor: 'pointer'}}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{opacity: 0.5}}
                                    />
                                )}
                                <Input
                                    type="number"
                                    name="numberOfAdults"
                                    id="numberOfAdults"
                                    value={numberOfAdults}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setNumberOfAdults(isNaN(value) ? 0 : value);
                                    }}
                                    disabled={!budget.expedient.id}
                                    style={{width: "100px", margin: "0 5px"}}
                                />
                                {budget.expedient.id ? (
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        onClick={handleIncrementAdults}
                                        style={{cursor: 'pointer'}}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{opacity: 0.5}}
                                    />
                                )}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="numberOfKids">Nº niños</Label>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Input
                                        type="number"
                                        name="numberOfKids"
                                        value={budget.people?.kids?.length || 0}
                                        disabled={!budget.expedient.id}
                                        readOnly
                                        style={{width: "100px", margin: "0 5px"}}
                                    />
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        onClick={handleAddKid}
                                        style={{cursor: 'pointer'}}
                                    />
                                </div>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="numberOfBabies">Nº bebes</Label>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {budget.expedient.id && numberOfBabies > 0 ? (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        onClick={handleDecrementBabies}
                                        style={{cursor: 'pointer'}}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{opacity: 0.5}}
                                    />
                                )}
                                <Input
                                    type="number"
                                    name="numberOfBabies"
                                    id="numberOfBabies"
                                    value={numberOfBabies}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setNumberOfBabies(isNaN(value) ? 0 : value);
                                    }}
                                    disabled={!budget.expedient.id}
                                    style={{width: "100px", margin: "0 5px"}}
                                />
                                {budget.expedient.id ? (
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        onClick={handleIncrementBabies}
                                        style={{cursor: 'pointer'}}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{opacity: 0.5}}
                                    />
                                )}
                            </div>
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {kidAges.map((age, index) => (
                                <div key={age + '_' + index} style={{display: 'flex', alignItems: 'center'}}>
                                    <FormGroup>
                                        <Label for={`ageDropdown-${index}`}>Edad niño {index + 1}</Label>
                                        <Dropdown isOpen={kidAgeDropdownOpen[index]}
                                                  toggle={() => toggleKidAgeDropdown(index)}>
                                            <DropdownToggle caret color="white">
                                                {age}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {[...Array(16)].map((_, i) => (
                                                    <DropdownItem key={index + '_' + i}
                                                                  onClick={() => handleKidAgeChange(index, i + 2)}>
                                                        {i + 2}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                onClick={() => handleRemoveKid(index)}
                                                style={{cursor: 'pointer'}}
                                            />
                                        </Dropdown>
                                    </FormGroup>
                                </div>
                            ))}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label for="customers">Cliente(s)</Label>
                        <Input
                            type="text"
                            name="customers"
                            id="customers"
                            value={budget.customers || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            autoComplete="customers"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="phone">Teléfono</Label>
                        <Input
                            type="text"
                            name="expedient.caseOpening.phone"
                            id="phone"
                            value={budget.expedient.caseOpening.phone || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            autoComplete="phone"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                            type="text"
                            name="expedient.caseOpening.email"
                            id="email"
                            value={budget.expedient.caseOpening.email || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            autoComplete="email"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="fromCity">Ciudad Origen</Label>
                        <Input
                            type="text"
                            name="expedient.caseOpening.fromCity"
                            id="fromCity"
                            value={budget.expedient.caseOpening.fromCity || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            autoComplete="fromCity"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="availability">Disponibilidad</Label>
                        <Input
                            type="text"
                            name="expedient.caseOpening.availability"
                            id="availability"
                            value={budget.expedient.caseOpening.availability || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            autoComplete="availability"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="tripOfInterest">Viaje de Interés</Label>
                        <Input
                            type="text"
                            name="expedient.caseOpening.tripOfInterest"
                            id="tripOfInterest"
                            value={budget.expedient.caseOpening.tripOfInterest || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            autoComplete="tripOfInterest"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="entrypoint">Vía de Entrada</Label>
                        <Input
                            type="text"
                            name="expedient.caseOpening.entrypoint"
                            id="entrypoint"
                            value={budget.expedient.caseOpening.entrypoint || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            autoComplete="entrypoint"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="comments">Comentarios</Label>
                        <Input
                            type="text"
                            name="expedient.caseOpening.comments"
                            id="comments"
                            value={budget.expedient.caseOpening.comments || ''}
                            onChange={handleChange}
                            disabled={!budget.expedient.id}
                            autoComplete="comments"/>
                    </FormGroup>
                    <FormGroup>
                        <Button color="success" type="submit" disabled={!budget.expedient?.id}>Guardar</Button>{' '}
                        <Button color="danger" tag={Link} to="/budgets">Cancelar</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    )
};

export default BudgetEdit;
