import {initializeApp} from 'firebase/app';
import {getAuth, signInWithPopup, GoogleAuthProvider} from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC4NHP2tad7EJnLe-hfGfkTlg_rd8CwSeY",
    authDomain: "app.travelcat.es",
    projectId: "travelcat-webapp",
    storageBucket: "travelcat-webapp.appspot.com",
    messagingSenderId: "597676212485",
    appId: "1:597676212485:web:f60ff8b19bea07625d776c",
    measurementId: "G-3VBN7YR9YN"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get the Firebase Auth instance
const auth = getAuth(app);

// Create a GoogleAuthProvider instance
const googleAuthProvider = new GoogleAuthProvider();

export {auth, signInWithPopup, googleAuthProvider};
